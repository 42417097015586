// src/components/FileManager.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Paper, TextField, IconButton, Card, CardHeader, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
const API_URL = 'https://wowgu.com/api';
// const API_URL = 'http://localhost:5208/api';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#f4f6f8',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
}));

function FileManager() {
  const [files, setFiles] = useState([]);
  const [directories, setDirectories] = useState([]);
  const [newDirectory, setNewDirectory] = useState('');
  const [editDirectory, setEditDirectory] = useState(null);
  const [editDirectoryName, setEditDirectoryName] = useState('');
  const [expanded, setExpanded] = useState({});
  const navigate = useNavigate();

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${API_URL}/files`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const fetchDirectories = async () => {
    try {
      const response = await axios.get(`${API_URL}/file-directories`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setDirectories(response.data);
    } catch (error) {
      console.error('Error fetching directories:', error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/admin/login');
    } else {
      fetchFiles();
      fetchDirectories();
    }
  }, [navigate]);

  const handleAddDirectory = async () => {
    if (newDirectory.trim()) {
      try {
        await axios.post(`${API_URL}/file-directories`, { name: newDirectory }, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        setNewDirectory('');
        fetchDirectories();
      } catch (error) {
        console.error('Error creating directory:', error);
      }
    }
  };

  const handleEditDirectory = (directory) => {
    setEditDirectory(directory.id);
    setEditDirectoryName(directory.name);
  };

  const handleUpdateDirectory = async () => {
    try {
      await axios.put(`${API_URL}/file-directories/${editDirectory}`, { name: editDirectoryName }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setEditDirectory(null);
      setEditDirectoryName('');
      fetchDirectories();
    } catch (error) {
      console.error('Error updating directory:', error);
    }
  };

  const handleDeleteDirectory = async (directoryId) => {
    const password = prompt('Please enter the password to delete this directory:');
    if (password === '5209') {
      try {
        await axios.delete(`${API_URL}/file-directories/${directoryId}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          data: { password },
        });
        fetchDirectories();
        fetchFiles();
      } catch (error) {
        console.error('Error deleting directory:', error);
      }
    } else {
      alert('Invalid password. Directory not deleted.');
    }
  };

  const handleToggleExpand = (directoryId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [directoryId]: !prevExpanded[directoryId],
    }));
  };

  const handleFileUpload = async (event, directoryId) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('directory_id', directoryId);

    try {
      await axios.post(`${API_URL}/files`, formData, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'multipart/form-data' },
      });
      fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await axios.delete(`${API_URL}/files/${fileId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
const handleDownloadFile = async (fileId) => {
  try {
    const response = await axios.get(`${API_URL}/files/${fileId}/download`, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'downloaded_file';

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (fileNameMatch) {
        fileName = decodeURIComponent(fileNameMatch[1]);
      }
    }

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};





  const Directory = ({ id, name }) => {
    return (
      <div style={{ marginBottom: '0.5rem' }}>
        <StyledPaper>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '0.875rem', cursor: 'pointer' }} onClick={() => handleToggleExpand(id)}>
              {name}
            </Typography>
            <div>
              <Typography variant="body2" style={{ marginRight: '0.5rem' }}>
                {files.filter((file) => file.directory_id === id).length} 文件
              </Typography>
              <input
                accept="*"
                style={{ display: 'none' }}
                id={`file-upload-${id}`}
                type="file"
                onChange={(event) => handleFileUpload(event, id)}
              />
              <label htmlFor={`file-upload-${id}`}>
                <IconButton size="small" component="span">
                  <AddIcon />
                </IconButton>
              </label>
              <IconButton onClick={() => handleEditDirectory({ id, name })} size="small">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteDirectory(id)} size="small">
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <Collapse in={expanded[id]}>
            <div style={{ paddingLeft: '1rem' }}>
              {files.filter((file) => file.directory_id === id).map((file) => (
                <StyledCard key={file.id}>
                  <CardHeader
                    title={<StyledTypography>{file.name}</StyledTypography>}
                    subheader={`上传时间: ${file.uploaded_at} 文件大小: ${file.size} KB`}
                    action={
                      <>
                        <IconButton onClick={() => handleDownloadFile(file.id)}>
                          <DownloadIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteFile(file.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  />
                </StyledCard>
              ))}
            </div>
          </Collapse>
        </StyledPaper>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: '#4b7983' }}>
        <Toolbar>
          <Typography variant="h7" style={{ color: '#FFFFFF', flexGrow: 1 }}>文件管理</Typography>
          <Button color="inherit" onClick={() => navigate('/admin')}>返回文章管理</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ marginTop: '1rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper style={{ padding: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <TextField
                  label="新建目录"
                  value={newDirectory}
                  onChange={(e) => setNewDirectory(e.target.value)}
                  size="small"
                  style={{ flexGrow: 1, marginRight: '0.5rem' }}
                />
                <IconButton onClick={handleAddDirectory}>
                  <AddIcon />
                </IconButton>
              </div>
              <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {directories.map((directory) => (
                  <Directory key={directory.id} id={directory.id} name={directory.name} />
                ))}
              </div>
              {editDirectory && (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                  <TextField
                    label="编辑目录"
                    value={editDirectoryName}
                    onChange={(e) => setEditDirectoryName(e.target.value)}
                    size="small"
                    style={{ flexGrow: 1, marginRight: '0.5rem' }}
                  />
                  <IconButton onClick={handleUpdateDirectory}>
                    <CheckIcon />
                  </IconButton>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper style={{ padding: '1rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                文件列表
              </Typography>
              <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {files.map((file) => (
                  <StyledCard key={file.id}>
                    <CardHeader
                      title={<StyledTypography>{file.name}</StyledTypography>}
                      subheader={`上传时间: ${file.uploaded_at} 文件大小: ${file.size} KB`}
                      action={
                        <>
                          <IconButton onClick={() => handleDownloadFile(file.id)}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteFile(file.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      }
                    />
                  </StyledCard>
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FileManager;
