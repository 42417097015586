// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ArticleList from './components/ArticleList';
import AdminLoginPage from './components/AdminLoginPage';
import AdminPage from './components/AdminPage';
import Sidebar from './components/Sidebar';
import FileManager from './components/FileManager';
import UserSettings from './components/UserSettings';  // 新的用户设置页面
import LoginPage from './components/LoginPage';

import axios from 'axios';


// const API_URL = 'http://localhost:5208/api';
const API_URL = 'https://wowgu.com/api';
function App() {
    const isAuthenticated = !!localStorage.getItem('token');

  const [directories, setDirectories] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Set default Authorization header for all requests
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    fetchDirectories();
    fetchArticles();
  }, []);

  const fetchDirectories = async () => {
    try {
      const response = await axios.get(`${API_URL}/directories`);
      setDirectories(response.data);
    } catch (error) {
      console.error('Error fetching directories:', error);
    }
  };

  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${API_URL}/articles`);
      setArticles(response.data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  const handleCreateDirectory = async (newDirectory) => {
    try {
      await axios.post(`${API_URL}/directories`, { name: newDirectory });
      fetchDirectories(); // 重新获取目录列表
    } catch (error) {
      console.error('Error creating directory:', error);
    }
  };

  const handleMoveArticle = async (articleId, directoryId) => {
    try {
      await axios.put(`${API_URL}/articles/${articleId}`, { directory_id: directoryId });
      fetchArticles(); // 重新获取文章列表
    } catch (error) {
      console.error('Error moving article:', error);
    }
  };

  const handleDeleteDirectory = async (directoryId) => {
    try {
      await axios.delete(`${API_URL}/directories/${directoryId}`);
      fetchDirectories(); // 重新获取目录列表
    } catch (error) {
      console.error('Error deleting directory:', error);
    }
  };

  const handleUpdateDirectory = async (directoryId, name) => {
    try {
      await axios.put(`${API_URL}/directories/${directoryId}`, { name });
      fetchDirectories(); // 重新获取目录列表
    } catch (error) {
      console.error('Error updating directory:', error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <div style={{  height: '100vh' }}>
          <Sidebar
            directories={directories}
            articles={articles}
            addDirectory={handleCreateDirectory}
            moveArticle={handleMoveArticle}
            deleteDirectory={handleDeleteDirectory}
            updateDirectory={handleUpdateDirectory}
          />
          <Routes>
            <Route path="/" element={<ArticleList articles={articles} />} />
            <Route path="/admin/login" element={<AdminLoginPage />} />
            <Route path="/admin" element={<AdminPage articles={articles} />} />
            <Route path="/admin/files" element={<FileManager />} />
           <Route path="/admin/settings" element={<UserSettings />} />
                    <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={isAuthenticated ? <Navigate to="/admin" /> : <Navigate to="/login" />} />

          </Routes>
        </div>
      </Router>
    </DndProvider>
  );
}

export default App;
