// 在 src/components/UserSettings.js 中

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// const API_URL = 'http://localhost:5208/api';
const API_URL = 'https://wowgu.com/api';

const UserSettings = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${API_URL}/user-settings`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        setEmail(response.data.email);
        setSecondPassword(response.data.second_password);
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    };
    fetchSettings();
  }, []);

  const handleSendVerificationCode = async () => {
    try {
      await axios.post(`${API_URL}/send-verification-code`, { email });
      alert('Verification code sent to your email');
    } catch (error) {
      console.error('Error sending verification code:', error);
    }
  };

  const handleVerifyCode = async () => {
    try {
      await axios.post(`${API_URL}/verify-code`, { email, code: verificationCode });
      setIsVerified(true);
      alert('Email verified successfully');
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Invalid verification code');
    }
  };

  const handleUpdateSettings = async () => {
    try {
      await axios.put(`${API_URL}/user-settings`, { second_password: secondPassword }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      alert('Settings updated successfully');
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  return (
    <div style={{ backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: '#4b7983' }}>
        <Toolbar>
          <Typography variant="h6" style={{ color: '#FFFFFF', flexGrow: 1 }}>用户设置</Typography>
          <Button color="inherit" onClick={() => navigate('/admin')}>返回</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: '2rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>二级密码设置</Typography>
              <TextField
                label="邮箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                size="small"
                disabled={isVerified}
              />
              {!isVerified && (
                <>
                  <Button variant="contained" color="primary" onClick={handleSendVerificationCode} style={{ marginTop: '1rem' }}>
                    发送验证码
                  </Button>
                  <TextField
                    label="验证码"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                  <Button variant="contained" color="primary" onClick={handleVerifyCode} style={{ marginTop: '1rem' }}>
                    验证
                  </Button>
                </>
              )}
              {isVerified && (
                <>
                  <TextField
                    label="二级密码"
                    value={secondPassword}
                    onChange={(e) => setSecondPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                  <Button variant="contained" color="primary" onClick={handleUpdateSettings} style={{ marginTop: '1rem' }}>
                    更新设置
                  </Button>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UserSettings;
