// AdminPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Paper, TextField, IconButton, Menu, MenuItem, Card, CardHeader, Collapse, Drawer, FormControl, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const API_URL = 'https://wowgu.com/api';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4b7983',
    },
    secondary: {
      main: '#c22b5f',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h6: {
      fontWeight: 600,
      color: '#4b7983', // 修改为你想要的颜色
    },
    body2: {
      fontSize: '0.875rem',

    },
  },
  spacing: 4,
});

const StyledCard = styled(Card)(({ theme, selected }) => ({
  marginBottom: theme.spacing(0.5),
  cursor: 'pointer',
  backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiIconButton-root': {
      display: 'inline-flex',
    },
  },
  '& .MuiIconButton-root': {
    display: 'none',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
}));

function AdminPage() {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [directories, setDirectories] = useState([]);
  const [newDirectory, setNewDirectory] = useState('');
  const [editDirectory, setEditDirectory] = useState(null);
  const [editDirectoryName, setEditDirectoryName] = useState('');
  const [expanded, setExpanded] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuArticle, setMenuArticle] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const FileManager = () => {
    navigate('/admin/files');
  };

  const fetchArticles = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/articles`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Fetched articles:', response.data);
      const sortedArticles = sortItemsByModified(response.data);
      setArticles(sortedArticles);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, []);

  const fetchDirectories = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/directories`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Fetched directories:', response.data);
      setDirectories(response.data);
    } catch (error) {
      console.error('Error fetching directories:', error);
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/admin/login');
    } else {
      fetchArticles();
      fetchDirectories();
    }
  }, [navigate, fetchArticles, fetchDirectories]);

  const sortItemsByModified = (items) => {
    return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  };

  const createArticle = async (directoryId) => {
    if (!title.trim() || !content.trim()) {
      alert('标题和内容不能为空');
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/articles`, { title, content, isPublic, directory_id: directoryId }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setTitle('');
      setContent('');
      setIsPublic(false);
      fetchArticles();
      setSelectedArticle(response.data);
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [directoryId]: true,
      }));
    } catch (error) {
      console.error('Error creating article:', error);
    }
  };

  const updateArticle = async (stayInEditMode = false) => {
    try {
      await axios.put(`${API_URL}/articles/${selectedArticle.id}`, { title, content, isPublic }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (!stayInEditMode) {
        setSelectedArticle(null);
        setTitle('');
        setContent('');
        setIsPublic(false);
      }
      fetchArticles();
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const deleteArticle = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this article?');
    if (confirmDelete) {
      try {
        await axios.delete(`${API_URL}/articles/${id}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        fetchArticles();
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setTitle(article.title);
    setContent(article.content);
    setIsPublic(article.isPublic);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin/login');
  };

  const handleAddDirectory = async () => {
    if (newDirectory.trim()) {
      try {
        await axios.post(`${API_URL}/directories`, { name: newDirectory });
        setNewDirectory('');
        fetchDirectories();
      } catch (error) {
        console.error('Error creating directory:', error);
      }
    }
  };

  const handleEditDirectory = (directory) => {
    setEditDirectory(directory.id);
    setEditDirectoryName(directory.name);
  };

  const handleUpdateDirectory = async () => {
    try {
      await axios.put(`${API_URL}/directories/${editDirectory}`, { name: editDirectoryName });
      setEditDirectory(null);
      setEditDirectoryName('');
      fetchDirectories();
    } catch (error) {
      console.error('Error updating directory:', error);
    }
  };

  const handleDeleteDirectory = async (directoryId) => {
    const password = prompt('Please enter the password to delete this directory:');
    if (password === '5209') {
      try {
        await axios.delete(`${API_URL}/directories/${directoryId}`, { data: { password } });
        fetchDirectories();
        fetchArticles();
      } catch (error) {
        console.error('Error deleting directory:', error);
      }
    } else {
      alert('Invalid password. Directory not deleted.');
    }
  };

  const handleToggleExpand = (directoryId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [directoryId]: !prevExpanded[directoryId],
    }));
  };

  const handleMenuOpen = (event, article) => {
    setAnchorEl(event.currentTarget);
    setMenuArticle(article);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuArticle(null);
  };

  const handleMenuDelete = () => {
    deleteArticle(menuArticle.id);
    handleMenuClose();
  };

  const handleMenuMove = async (directoryId) => {
    await moveArticle(menuArticle.id, directoryId);
    handleMenuClose();
  };

  const truncateTitle = (title) => {
    return title ? (title.length > 20 ? `${title.substring(0, 20)}...` : title) : '';
  };

  const Article = ({ article }) => {
    const [, drag] = useDrag({
      type: 'article',
      item: { id: article.id },
    });

    return (
      <StyledCard
        ref={drag}
        onClick={(e) => {
          e.stopPropagation();
          handleArticleClick(article);
        }}
        selected={selectedArticle && selectedArticle.id === article.id}
        sx={{
          '&:hover': {
            backgroundColor: '#e0e0e0',
            '& .MuiIconButton-root': {
              display: 'inline-flex',
            },
          },
          '& .MuiIconButton-root': {
            display: 'none',
          },
        }}
      >
        <CardHeader
          action={
            <IconButton onClick={(event) => handleMenuOpen(event, article)}>
              <MoreVertIcon />
            </IconButton>
          }
          title={<StyledTypography>{truncateTitle(article.title)}</StyledTypography>}
          style={{ padding: '8px' }}  // 减少上下空白区域
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuDelete}>Delete</MenuItem>
          <MenuItem>Move to...</MenuItem>
          {directories.map((directory) => (
            <MenuItem key={directory.id} onClick={() => handleMenuMove(directory.id)} style={{ paddingLeft: '2rem' }}>
              {directory.name}
            </MenuItem>
          ))}
        </Menu>
      </StyledCard>
    );
  };

  const Directory = ({ id, name, isDefault }) => {
    const [, drop] = useDrop({
      accept: 'article',
      drop: (item) => moveArticle(item.id, id),
    });

    return (
      <div ref={drop} style={{ marginBottom: '0.5rem' }}>
        <StyledPaper>
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => handleToggleExpand(id)}
          >
            {editDirectory === id ? (
              <TextField
                value={editDirectoryName}
                onChange={(e) => setEditDirectoryName(e.target.value)}
                onBlur={handleUpdateDirectory}
                size="small"
                autoFocus
                style={{ flexGrow: 1, marginRight: '0.5rem' }}
              />
            ) : (
              <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1rem', flexGrow: 1 }}>
                {name}
              </Typography>
            )}
            <div onClick={(e) => e.stopPropagation()}>
              {!isDefault && (
                <>
                  {articles.filter((article) => article.directory_id === id).length}
                  <IconButton onClick={() => handleEditDirectory({ id, name })} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteDirectory(id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </div>
          </div>
          <Collapse in={expanded[id]}>
            <div style={{ paddingLeft: '1rem' }}>
              {articles.filter((article) => article.directory_id === id).map((article) => (
                <Article key={article.id} article={article} />
              ))}
            </div>
          </Collapse>
        </StyledPaper>
      </div>
    );
  };

  const moveArticle = async (articleId, directoryId) => {
    try {
      await axios.put(`${API_URL}/articles/${articleId}`, { directory_id: directoryId }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      fetchArticles();
    } catch (error) {
      console.error('Error moving article:', error);
    }
  };

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      const query = e.target.value.toLowerCase();
      if (query) {
        try {
          const response = await axios.get(`${API_URL}/articles`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          });
          const filteredArticles = response.data.filter(article => article.title.toLowerCase().includes(query));
          setArticles(filteredArticles);
        } catch (error) {
          console.error('Error fetching articles:', error);
        }
      } else {
        fetchArticles();
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.altKey && e.key === 'd') {
      e.preventDefault();
      if (selectedArticle) {
        deleteArticle(selectedArticle.id);
      }
    } else if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      if (selectedArticle) {
        updateArticle(true);
      } else {
        createArticle();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, selectedArticle, title, content]);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <div style={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)} sx={{ mr: 1 }}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1 }}>管理面板</Typography>
              <TextField
                placeholder="搜索..."
                onKeyDown={handleSearch}
                size="small"
                variant="outlined"
                style={{ backgroundColor: '#fff', borderRadius: '4px', marginRight: '0.5rem', width: '150px', height: '30px', boxShadow: 'none' }}
                inputProps={{ style: { fontSize: '0.875rem', padding: '5px 10px' } }}
              />
              <Button color="inherit" onClick={FileManager} style={{ marginRight: '0.5rem' }}>文件管理</Button>
              <Button color="inherit" onClick={handleLogout}>注销</Button>
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <div style={{ width: '250px', padding: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <TextField
                  label="新建目录"
                  value={newDirectory}
                  onChange={(e) => setNewDirectory(e.target.value)}
                  size="small"
                  style={{ flexGrow: 1, marginRight: '0.5rem' }}
                />
                <IconButton onClick={handleAddDirectory} color="primary">
                  <FolderOpenIcon />
                </IconButton>
                <IconButton onClick={() => createArticle(1)} color="primary"> {/* Ensure default directory id is 1 */}
                  <NoteAddIcon />
                </IconButton>
              </div>
              <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {directories.map((directory) => (
                  <Directory key={directory.id} id={directory.id} name={directory.name} isDefault={directory.name === 'default'} />
                ))}
                {articles.filter(article => article.directory_id === 1).map((article) => ( // Display articles in default directory
                  <Article key={article.id} article={article} />
                ))}
              </div>
            </div>
          </Drawer>
          <Container maxWidth="lg" style={{ marginTop: '1rem', marginBottom: '2rem' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Paper style={{ padding: '1rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <TextField
                      label="新建目录"
                      value={newDirectory}
                      onChange={(e) => setNewDirectory(e.target.value)}
                      size="small"
                      style={{ flexGrow: 1, marginRight: '0.5rem' }}
                    />
                    <IconButton onClick={handleAddDirectory} color="primary">
                      <FolderOpenIcon />
                    </IconButton>
                    <IconButton onClick={() => createArticle(1)} color="primary"> {/* Ensure default directory id is 1 */}
                      <NoteAddIcon />
                    </IconButton>
                  </div>
                  <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {directories.map((directory) => (
                      <Directory key={directory.id} id={directory.id} name={directory.name} isDefault={directory.name === 'default'} />
                    ))}
                    {articles.filter(article => article.directory_id === 1).map((article) => ( // Display articles in default directory
                      <Article key={article.id} article={article} />
                    ))}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '1rem' }}>
                  {selectedArticle ? (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                        <Typography variant="h6">编辑文章</Typography>
                        <Typography variant="body2">
                        创建时间: {selectedArticle.created_at}
                      </Typography>
                      </div>

                    </>
                  ) : (
                    <Typography variant="h6" style={{ marginBottom: '1rem' }}>新建文章</Typography>
                  )}
                  <TextField
                    label="标题"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="内容"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    fullWidth
                    multiline
                    minRows={20}
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {selectedArticle && (
                      <Typography variant="body2">修改时间: {selectedArticle.updated_at}</Typography>
                    )}
                    {selectedArticle ? (
                      <Button variant="contained" color="primary" onClick={() => updateArticle(false)}>
                        更新文章
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary" onClick={() => createArticle(selectedArticle?.directory_id || 1)}>
                        创建文章
                      </Button>
                    )}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      </DndProvider>
    </ThemeProvider>
  );
}

export default AdminPage;
