// src/components/ArticleList.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, TextField, Paper, Container } from '@material-ui/core';
// import axios from 'axios';

// const API_URL = 'http://localhost:5208/api';

// const API_URL = 'http://localhost:5208/api';
// const API_URL = 'https://wowgu.com/api';

function ArticleList({ articles }) {
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFilteredArticles(articles);
  }, [articles]);
  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setFilteredArticles(
      articles.filter((article) =>
        article.title.toLowerCase().includes(query.toLowerCase()) ||
        article.content.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  return (
    <div style={{ backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: '#4b7983' }}>
        <Toolbar>
          <Typography variant="h6" style={{ color: '#FFFFFF', flexGrow: 1 }}>我的笔记</Typography>
          <TextField
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            style={{ backgroundColor: '#FFFFFF', marginLeft: '1rem' }}
          />
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ marginTop: '2rem', display: 'flex' }}>
        <div className="left-sidebar" style={{ width: '30%', marginRight: '1rem' }}>
          <Paper style={{ padding: '1rem', backgroundColor: '#FFFFFF' }}>
            {/*<Typography variant="h6" style={{ color: '#69696c' }}>Articles</Typography>*/}
            <ul className="directory">
              {filteredArticles.map((article) => (
                <li key={article.id}>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#a8a5a5',
                      cursor: 'pointer',
                      marginBottom: '1rem',
                      maxWidth: '80%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => handleArticleClick(article)}
                  >
                    {article.title}
                  </Typography>
                  {/*<Typography variant="caption" style={{ color: '#999999' }}>*/}
                  {/*  Directory: {article.directory_id}*/}
                  {/*</Typography>*/}
                </li>
              ))}
            </ul>
          </Paper>
        </div>
        <div style={{ width: '70%', backgroundColor: '#FFFFFF', padding: '1rem' }}>
          {selectedArticle ? (
            <>
              <Typography variant="h5" style={{ color: '#8389a9', textAlign: 'center' }}>{selectedArticle.title}</Typography>
              <Typography variant="caption" style={{ color: '#999999', display: 'block', textAlign: 'center' }}>{selectedArticle.created_at}</Typography>
              <Typography variant="body1" className="content" style={{ color: '#666666', marginTop: '1rem', whiteSpace: 'pre-wrap', marginLeft: '1rem' }}>
                {selectedArticle.content}
              </Typography>
            </>
          ) : (
            <Typography variant="body1" style={{ color: '#666666' }}>记录各种笔记.</Typography>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ArticleList;
