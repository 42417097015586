// src/components/AdminLoginPage.js
import React, { useState, useRef } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import axios from 'axios';

// 定义API URL
const API_URL = 'https://wowgu.com/api';
// const API_URL = 'https://localhost:5208/api';
// 管理员登录页面组件
function AdminLoginPage() {
  const [username, setUsername] = useState('admin');
  const [password, setPassword] = useState('Aqaz123...');
  const [error, setError] = useState('');
  const buttonRef = useRef(null);

  // 处理登录逻辑
  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_URL}/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      window.location.href = '/admin';
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Invalid username or password');
    }
  };

  return (
    <div>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button ref={buttonRef} onClick={handleLogin}>Login</Button>
    </div>
  );
}

export default AdminLoginPage;
