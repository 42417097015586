// src/components/Sidebar.js
import React, { useState } from 'react';
import { IconButton, Typography, TextField, Drawer } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useDrag, useDrop } from 'react-dnd';
import axios from 'axios';

// const API_URL = 'http://localhost:5208/api';
const API_URL = 'https://wowgu.com/api';
const Sidebar = ({ directories, articles, addDirectory, moveArticle, deleteDirectory, updateDirectory }) => {
  const [newDirectory, setNewDirectory] = useState('');
  // const [editDirectory, setEditDirectory] = useState(null);
  // const [editDirectoryName, setEditDirectoryName] = useState('');
  // const [expanded, setExpanded] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  //
  // const handleToggleExpand = (directoryId) => {
  //   setExpanded((prevExpanded) => ({
  //     ...prevExpanded,
  //     [directoryId]: !prevExpanded[directoryId],
  //   }));
  // };
  //
  // const handleDrawerToggle = () => {
  //   setDrawerOpen(!drawerOpen);
  // };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleAddDirectory = async () => {
    if (newDirectory.trim()) {
      try {
        await axios.post(`${API_URL}/directories`, { name: newDirectory });
        setNewDirectory('');
        addDirectory();
      } catch (error) {
        console.error('Error creating directory:', error);
      }
    }
  };
  //
  // const handleEditDirectory = (directory) => {
  //   setEditDirectory(directory.id);
  //   setEditDirectoryName(directory.name);
  // };
  //
  // const handleUpdateDirectory = async () => {
  //   try {
  //     await axios.put(`${API_URL}/directories/${editDirectory}`, { name: editDirectoryName });
  //     setEditDirectory(null);
  //     setEditDirectoryName('');
  //     updateDirectory();
  //   } catch (error) {
  //     console.error('Error updating directory:', error);
  //   }
  // };
  //
  // const handleDeleteDirectory = async (directoryId) => {
  //   try {
  //     await axios.delete(`${API_URL}/directories/${directoryId}`);
  //     deleteDirectory();
  //   } catch (error) {
  //     console.error('Error deleting directory:', error);
  //   }
  // };

  const moveArticleToDirectory = async (articleId, directoryId) => {
    try {
      await axios.put(`${API_URL}/articles/${articleId}`, { directory_id: directoryId });
      moveArticle();
    } catch (error) {
      console.error('Error moving article:', error);
    }
  };

  const Article = ({ article }) => {
    const [, drag] = useDrag({
      type: 'article',
      item: { id: article.id },
    });

    return (
      <div ref={drag} style={{ marginBottom: '0.5rem', cursor: 'pointer' }}>
        {/*<Typography variant="body2" onClick={() => handleArticleClick(article)}>*/}
        {/*  {article.title}*/}
        {/*</Typography>*/}
      </div>
    );
  };

  const Directory = ({ id, name, isDefault }) => {
    const [, drop] = useDrop({
      accept: 'article',
      drop: (item) => moveArticleToDirectory(item.id, id),
    });

    return (
      <div ref={drop} style={{ marginBottom: '0.5rem' }}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
          {name}
        </Typography>
        <div style={{ paddingLeft: '1rem' }}>
          {articles.filter((article) => article.directory_id === id).map((article) => (
            <Article key={article.id} article={article} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {/*<IconButton onClick={handleDrawerToggle} style={{ position: 'fixed', bottom: 16, left: 16, zIndex: 1000 }}>*/}
      {/*  <MenuIcon />*/}
      {/*</IconButton>*/}
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <div
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
          style={{ width: 250, padding: 16 }}
        >
          <IconButton onClick={handleDrawerClose} style={{ marginBottom: '1rem' }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ color: '#3f51b5' }}>Directories & Articles</Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <TextField
              label="New Directory"
              value={newDirectory}
              onChange={(e) => setNewDirectory(e.target.value)}
              size="small"
              style={{ flexGrow: 1, marginRight: '0.5rem' }}
            />
            <IconButton onClick={handleAddDirectory}>
              <AddIcon />
            </IconButton>
          </div>
          <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            {articles.filter(article => article.directory_id === 1).map((article) => (
              <Article key={article.id} article={article} />
            ))}
            {directories.map((directory) => (
              <Directory key={directory.id} id={directory.id} name={directory.name} isDefault={directory.name === 'default'} />
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
